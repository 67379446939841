import image from "../assets/rocket.png";
import Links from "./Links";
import { motion } from "framer-motion";
const Hero = () => {
	console.log(image);

	return (
		<div
			className="h-screen flex items-center justify-center"
			style={{
				backgroundImage: `url(${image})`,
				backgroundSize: "100%",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "right bottom",
			}}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0 }}
				animate={{ opacity: 1, scale: 1 }}
				className="flex flex-col items-center justify-center text-center gap-7"
			>
				<p className="text-2xl font-semibold">Welcome To</p>
				<div className="p-2 relative">
                    <motion.div className="absolute blur-xl inset-0 p-4 bg-[radial-gradient(circle_farthest-side_at_100%_0,#a320cc,transparent),radial-gradient(circle_farthest-side_at_0_100%,#3357b9,transparent)]"

                        initial={{  backgroundPosition: "0 50%"}}
                    animate={{ backgroundPosition: ["0% 50%", "100% 100%", "0% 50%"]}}
                    transition={{ duration: 5, repeat: Infinity }}
                style={{
                        backgroundSize: "400% 400%",
                        }}

                    />
                    <motion.div className="absolute rounded-xl inset-0  bg-[radial-gradient(circle_farthest-side_at_100%_0,#a320cc,transparent),radial-gradient(circle_farthest-side_at_0_100%,#3357b9,transparent),radial-gradient(circle_farthest-side_at_0_0,#dddb1b,transparent),radial-gradient(circle_farthest-side_at_0_0,#1bdd1b,transparent)]"
                        initial={{  backgroundPosition: "0 50%"}}
                    animate={{ backgroundPosition: ["0% 50%", "100% 100%", "0% 50%"]}}
                    transition={{ duration: 5, repeat: Infinity }}
                    style={{
                        backgroundSize: "400% 400%",
                        }}
                        />
					<div className="relative p-5 bg-black rounded-xl flex flex-col gap-2">
						<h1
							className="text-2xl md:text-6xl text-yellow-500 font-bold shadow-xl"
							style={{ fontFamily: "Orbitron, sans-serif" }}
						>
							Starlight Voyager
						</h1>
						<p className="text-base text-yellow-500">
							A web server I use to host my personal projects and
							prototypes
						</p>
					</div>
				</div>

				<Links />
			</motion.div>
		</div>
	);
};

export default Hero;
