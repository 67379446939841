import { motion } from "framer-motion";
const LINKS = [
	{
		name: "Frasier Sundra",
		link: "https://frasiersundra.starlightvoyager.dev",
	},
	{
		name: "Jasmine Clarke",
		link: "https://jasmine.starlightvoyager.dev",
	},
	{
		name: "JLSM",
		link: "https://jlsm.starlightvoyager.dev",
	},
	{
		name: "HiguVt",
		link: "https://higuvt.starlightvoyager.dev",
	},
];
const Links = () => {
	return (
		<div>
			<h4 className="text-lg"> Current Projects </h4>
			<div className="bg-black p-4 bg-opacity-40 rounded-xl flex gap-4 items-center justify-center flex-wrap">
				{LINKS.map((link, index) => (
					<motion.a
						key={index}
						whileHover={{ y: -5 }}
						href={link.link}
					>
						{link.name}
					</motion.a>
				))}
			</div>
		</div>
	);
};

export default Links;
