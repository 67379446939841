import Hero from "./components/Hero";

function App() {
  return (

        <main className="min-h-screen bg-black text-white">
            <Hero />
        </main>
  );
}

export default App;
